import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';


export default class Home extends Component {
  render() {
    return (
    <main className="home">
      {this.renderHero()}
      {this.renderPropertyInfo()}
      {this.renderGallery()}
      {this.renderReviews()}
      {this.renderFixedCallButton()}
    </main>
    );
  };

  renderHero(){
    return(
        <section className="hero-box">
          <img src={require('../images/street_view.jpg')} alt="Street view of the Rico Mine Shaft Inn" width="100%" />
        </section>
    );
  };

  renderPropertyInfo(){
    return(
      <div className="container">
        <section className="about-us section">
            <h2 className="about-us-header secton-header">History and Beauty</h2>
            <p>
              Located in colorful Rico, Colorado we offer rooms with both private and shared bathrooms, as well as breakfast and cooking facilities for all of our guests. Surrounded by the beautiful San Juan National Forest, the possibilities for any nature lover are endless. Whether you enjoy hikes, fishing, biking, soaking in hotsprings, or even just relaxing on our beautiful back patio and enjoying the serenity, Rico has something for everyone. We are only 30 minutes from Telluride, where during the summer there is a different festival happening every week. Come stay with us and explore what the hidden gem of Rico has to offer!
            </p>
          </section>
      </div>
    );
  };

  renderGallery() {
    const images = [
      {
        original: require('../images/home_gallery/Guest-Reception.jpg'),
        thumbnail: require('../images/home_gallery/Guest-Reception.jpg'),
      },
      {
        original: require('../images/home_gallery/Great-Room.jpg'),
        thumbnail: require('../images/home_gallery/Great-Room.jpg'),
      },
      {
        original: require('../images/home_gallery/Great-Room-1.jpg'),
        thumbnail: require('../images/home_gallery/Great-Room-1.jpg'),
      },
      {
        original: require('../images/home_gallery/Parlor.jpg'),
        thumbnail: require('../images/home_gallery/Parlor.jpg'),
      },
      {
        original: require('../images/home_gallery/DSCN1872.jpg'),
        thumbnail: require('../images/home_gallery/DSCN1872.jpg'),
      },
      {
        original: require('../images/home_gallery/Back-Patio-1.jpg'),
        thumbnail: require('../images/home_gallery/Back-Patio-1.jpg'),
      },
      {
        original: require('../images/home_gallery/Back-Patio.jpg'),
        thumbnail: require('../images/home_gallery/Back-Patio.jpg'),
      },
      {
        original: require('../images/home_gallery/Room-1.jpg'),
        thumbnail: require('../images/home_gallery/Room-1.jpg'),
      },
      {
        original: require('../images/home_gallery/Room-1-Bathroom.jpg'),
        thumbnail: require('../images/home_gallery/Room-1-Bathroom.jpg'),
      },
      {
        original: require('../images/home_gallery/Room-2.jpg'),
        thumbnail: require('../images/home_gallery/Room-2.jpg'),
      },
      {
        original: require('../images/home_gallery/Room-2-Bathroom.jpg'),
        thumbnail: require('../images/home_gallery/Room-2-Bathroom.jpg'),
      },
      {
        original: require('../images/home_gallery/Room-3.jpg'),
        thumbnail: require('../images/home_gallery/Room-3.jpg'),
      },
    ]

    return (
      <div className='container'>
        <div className='imageGallery section'>
          <ImageGallery items={images} showPlayButton={false} />
        </div>
        <hr className="hr-gradiant" />
      </div>
    )
  };

  renderReviews(){
    return(
      <div className="container">
        <section className="reviews section">
          <h2 className="reviews-header secton-header">Testimonials</h2>
          <div className="row">
            {this.renderReview1()}
            {this.renderReview2()}
            {this.renderReview3()}
          </div>
          <a href="https://search.google.com/local/writereview?placeid=ChIJY530oMbMPocRHYBxiI5ZRqo" target="blank"><h5 className="leave-review" >Write a Review</h5></a>
          <a href="https://search.google.com/local/reviews?placeid=ChIJY530oMbMPocRHYBxiI5ZRqo" target="blank"><h5 className="leave-review" >Read More</h5></a>
        </section>
      </div>
    );
  };
  
  renderReview1(){
    return(
      <div className="col-md-4">
        <article className="review">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Hope Hamilton</h4>
                {/* <h6 className="card-subtitle mb-2 text-muted">-A Month Ago</h6> */}
              <p className="card-text">It is my favorite B&B in Co. I loved the bath tub. I haven’t been in a tub in 18 yrs. ( I’m 85, so this was special. )Jordan, the owner, is a delightful hostess. She grew up in Rico. Her dad is fabulous cook & her boyfriend, a charming waiter. Dinner was out of the out of this world. Breakfast, too. The beds are soft as a cloud. I love everything about it. ( Don’t tell anyone because Telluride is over run & way over rated & I don’t want tourist to ruin iRico.
                Hope Almy Hamilton )</p>
              </div>
            </div>
        </article>
      </div>
    );
  };

  renderReview2(){
    return(
      <div className="col-md-4">
        <article className="review">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Jackson Ellis-Luca</h4>
              {/* <h6 className="card-subtitle mb-2 text-muted">-A Week Ago</h6> */}
              <p className="card-text">The place is beautiful, the location is awesome, and the breakfast is tasty. The absolute best part of staying here though is the customer service! We could not have asked for a better stay.</p>
            </div>
          </div>
        </article>
      </div>
    );
  };

  renderReview3(){
    return(
      <div className="col-md-4">
        <article className="review">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Emily Kamala</h4>
              {/* <h6 className="card-subtitle mb-2 text-muted">-A Week Ago</h6> */}
              <p className="card-text">I'm so grateful to have found this gem!
                There's so much beauty and detail in this Inn. A reminder of a time when elegance and quality were valued.
                My bed was cozy, quiet, and incredibly comfortable. Breakfast was perfect and I wish I was able to stay longer. Will look for a time when I can bring my kids back to stay in the room with bunk beds!!</p>
            </div>
          </div>
        </article>
      </div>
    );
  };

  renderFixedCallButton(){
    return(
      <div className="fixed-call-button-wrapper">
        <a className="fixed-call-button" href="tel:+1-970-967-4996">Call (970)967-4996</a>
      </div>
    )
  };
};